<template>
  <div class="container">
    <el-form :model="form"
             :rules="rules"
             label-position="top"
             ref="form">
      <el-form-item label="权益名称"
                    class="width300"
                    prop="name">
        <el-input type="text"
                  placeholder="请输入权益名称"
                  v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="权益图片"
                    prop="img_id">
        <ImgUpload :fileList.sync="imgList"
                   pixel="建议使用120*120像素"
                   v-model="form.img_id" />
      </el-form-item>
      <el-form-item label="派发时间"
                    prop="started_at">
        <el-date-picker v-model="dateTime"
                        @change="dateChange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :default-time="['00:00:00', '23:59:59']"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="权益类型"
                    required>
        <el-radio-group v-model="form.type"
                        :disabled="form.id?true:false">
          <el-radio label="COUPON">代金券</el-radio>
          <el-radio label="CARD_KEY">卡密</el-radio>
          <el-radio label="REFUEL">加油券</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="关联优惠券"
                    prop="coupon_id"
                    v-if="form.type === 'COUPON'"
                    class="width300">
        <el-row>
          <el-select v-model="value"
                     filterable
                     remote
                     placeholder="请输入批次号"
                     :disabled="form.id !== ''"
                     :remote-method="remoteMethod"
                     @change="value = ''"
                     :loading="selectLoading">
            <el-option v-for="item in options"
                       :key="item.id"
                       :label="item.title"
                       :value="item.id">
              <div @click="onClick(item)">
                {{item.id}}-{{item.coupon_stock_id}}-{{item.title}}
              </div>
            </el-option>
          </el-select>
        </el-row>
        <el-row class="mt-15"
                type="flex">
          <el-tag type="success"
                  class="mr-15"
                  :closable="form.id === ''"
                  @close="
                  close(index)"
                  v-for="(item,index) in couponList"
                  :key="index">{{item.id}}-{{item.coupon_stock_id}}-{{item.title}}</el-tag>
        </el-row>

      </el-form-item>
      <el-form-item label="卡密id"
                    v-if="form.type === 'CARD_KEY'"
                    prop="card_id"
                    key="card_id"
                    class="width300">
        <el-input type="text"
                  placeholder="请输入卡密id"
                  :disabled="form.id !== ''"
                  v-model.number="form.card_id"></el-input>
      </el-form-item>
      <el-form-item label="加油券id"
                    v-if="form.type === 'REFUEL'"
                    prop="refuel_id"
                    key="refuel_id"
                    class="width300">
        <el-input type="text"
                  placeholder="请输入加油券id"
                  :disabled="form.id !== ''"
                  v-model.number="form.refuel_id"></el-input>
      </el-form-item>
      <el-form-item label="加油券说明"
                    v-if="form.type === 'REFUEL'"
                    prop="refuel_desc"
                    key="refuel_desc"
                    class="width300">
        <el-input type="textarea"
                  placeholder="请填写加油券说明"
                  :disabled="form.id !== ''"
                  v-model="form.refuel_desc"></el-input>
      </el-form-item>
      <el-form-item label="加油券图片"
                    prop="refuel_img_id">
        <ImgUpload :fileList.sync="refuelImgList"
                   pixel="建议使用120*120像素"
                   v-model="form.refuel_img_id" />
      </el-form-item>

      <el-form-item label="库存"
                    prop="total_inventory"
                    class="width300">
        <el-input type="text"
                  placeholder=""
                  v-model.number="form.total_inventory"></el-input>
      </el-form-item>
      <el-form-item label="排序"
                    prop="index"
                    class="width300">
        <el-input type="text"
                  placeholder="请输入库存，越小越靠前"
                  v-model.number="form.index"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('form')">立即{{form.id?'修改':'创建'}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { setEquity, getEquityDetail, getTabList } from '@/api/car'
import { fetchVouchers } from '@/api/market'
import ImgUpload from '@/components/ImgUpload'
import { getImgId } from '@/utils'
export default {
  name: 'SetKinghtEquity',
  data () {
    return {
      dateRule: {},
      value: '',
      couponList: [],
      imgList: [],
      imgList2: [],
      dateTime: [],
      selectLoading: false,
      areaAddress: {
        province: '',
        city: '',
        district: '',
        net: ''
      },
      coupons: [],
      options: [],
      refuelImgList: [],
      form: {
        name: '',
        img_id: 0,
        started_at: '',
        ended_at: '',
        type: 'COUPON',
        coupon_id: '',
        card_id: 0,
        prize_img_id: 0,
        desc: '',
        total_inventory: 0,
        index: 0,
        money: 0,
        id: '',
        refuel_id: '',
        refuel_desc: ''
      },
      rules: {
        province: [
          { required: true, message: '至少选择到省', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入权益名称', trigger: 'blur' }
        ],
        img_id: [
          { required: true, message: '请上传权益图片', trigger: 'change' }
        ],
        prize_img_id: [
          { required: true, message: '请上传奖品大图', trigger: 'change' }
        ],
        coupon_id: [
          { required: true, message: '请添加代金券', trigger: 'change' }
        ],
        card_id: [
          { required: true, message: '请输入卡密id', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入网点核销规则', trigger: 'change' }
        ],
        index: [
          { required: true, message: '请输入排序', trigger: 'blur' }
        ],
        total_inventory: [
          { required: true, message: '请输入库存', trigger: 'blur' }
        ],
        started_at: [
          { required: true, message: '请选择起止时间', trigger: 'change' }
        ],
        refuel_id: [
          { required: true, message: '请填写加油权id', trigger: 'change' }
        ],
        refuel_desc: [
          { required: true, message: '请填写加油券说明', trigger: 'change' }
        ],
        refuel_img_id: [
          { required: true, message: '请上传加油券图片', trigger: 'change' }
        ]
      }
    }
  },
  components: {
    ImgUpload
  },
  methods: {
    areaChange (address) {
      const { form } = this
      form.province = address.province
      form.city = address.city
      form.district = address.district
      form.net = address.net
    },
    async getTabList () {
      const res = await getTabList({ status: true })
      if (res.meta.code === 0) {
        this.tabList = res.data.label
      }
    },
    dateChange (e) {
      this.form.started_at = e[0]
      this.form.ended_at = e[1]
    },
    close (index) {
      this.form.coupon_id = ''
      this.couponList.splice(index, 1)
    },
    onClick (item) {
      if (this.form.coupon_id === item.id) {
        this.$message.error('请勿添加相同关联券')
      } else {
        this.form.coupon_id = item.id
        this.couponList[0] = item
      }
    },
    addressChange (res) {
      this.form.province = res.province
      this.form.city = res.city
      this.form.district = res.district
    },
    async remoteMethod (query) {
      if (query !== '' && query.length > 4) {
        this.selectLoading = true
        const res = await fetchVouchers({
          page: 1,
          size: 5,
          coupon_stock_id: query
        })
        this.selectLoading = false
        if (res.meta.code === 0) {
          this.options = res.data.coupons
        }
      } else {
        this.options = []
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await setEquity(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '创建'}成功`,
              type: 'success'
            })
            this.$router.push('carEquities')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    },
    async getEquityDetail (id) {
      const res = await getEquityDetail({
        id: id
      })
      if (res.meta.code === 0) {
        const activity = res.data.data
        activity.img_id = getImgId(activity.img_data)
        activity.img_data && this.$set(this.imgList, 0, activity.img_data)
        activity.prize_img_id = getImgId(activity.prize_img_data)
        activity.prize_img_data && this.$set(this.imgList2, 0, activity.prize_img_data)
        activity.refuel_img_id = getImgId(activity.refuel_img_data)
        activity.refuel_img_data && this.$set(this.refuelImgList, 0, activity.refuel_img_data)
        if (activity.coupon_id) {
          const coupon = await fetchVouchers({ coupon_id: activity.coupon_id })
          if (res.meta.code === 0) {
            this.couponList.push(coupon.data.coupons[0])
          }
        }
        this.form = activity
        this.dateTime = [activity.started_at, activity.ended_at]
        this.areaAddress = {
          province: activity.province,
          city: activity.city,
          district: activity.district
        }
      }
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.getEquityDetail(this.$route.query.id)
    }
    this.getTabList()
  }
}
</script>

<style lang="scss" scoped>
</style>
