import request from '@/utils/request'
// 权益列表
export function getEquityList (params) {
  return request({
    url: '/api/mall/admin/third/party/equity/list',
    method: 'get',
    params
  })
}
// 删除权益
export function delEquity (data) {
  return request({
    url: '/api/mall/admin/third/party/equity/del',
    method: 'post',
    data
  })
}
// 权益详情
export function getEquityDetail (params) {
  return request({
    url: '/api/mall/admin/third/party/equity/details',
    method: 'get',
    params
  })
}
// 编辑权益
export function setEquity (data) {
  return request({
    url: '/api/mall/admin/third/party/equity/edit',
    method: 'post',
    data
  })
}
// 权益查看数据
export function getEquityRecord (params) {
  return request({
    url: '/api/mall/admin/third/party/equity/receive/list',
    method: 'get',
    params
  })
}
// 获取标签列表
export function getTabList (params) {
  return request({
    url: '/api/mall/admin/equity/get/getLabelList',
    method: 'get',
    params
  })
}
// 删除标签
export function delTab (data) {
  return request({
    url: '/api/mall/admin/equity/post/delLabelById',
    method: 'post',
    data
  })
}
// 添加编辑标签
export function setTab (data) {
  return request({
    url: '/api/mall/admin/equity/post/editLabel',
    method: 'post',
    data
  })
}

// 权益骑士会员列表
export function knightMembers (params) {
  return request({
    url: '/api/mall/admin/knights/member/list',
    method: 'get',
    params
  })
}
// 会员参与详情
export function knightMemberDetail (params) {
  return request({
    url: '/api/mall/admin/knights/member/receive/list',
    method: 'get',
    params
  })
}
